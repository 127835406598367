import axios from "axios";
import toast from "react-hot-toast";
import insertQuery from "src/views/v2/utils/insertQuery";

let url;
if (process.env.REACT_APP_ENV === "production") {
  url = process.env.REACT_APP_API_production;
} else if (process.env.REACT_APP_ENV === "development") {
  url = `${process.env.REACT_APP_API_development}`;
} else {
  // if nothing matches, then it is local
  url = process.env.REACT_APP_API_local;
}

const API = axios.create({ baseURL: url });

API.interceptors.request.use((req) => {
  try {
    req.headers.Authorization = `Bearer ${localStorage.getItem("ph_token")}`;
  } catch (error) {
    console.warn("No token found");
  }
  return req;
});

// Adding error handling
API.interceptors.response.use(
  (response) => {
    // extract data from response
    return response;
  },
  (error) => {
    // dismiss the loading indicator
    toast.dismiss();
    // Do something with request error
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // if(error.response.status === 500) {
      // }

      toast.error(error.response?.data?.message || "Something went wrong");
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      toast.error(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
    }
    return error;
  }
);

export const login = (user) => {
  return API.post("/login", user);
};

export const signup = (data) => {
  return API.post("/signup", data);
};

export const checkInvitationCode = (code) => {
  return API.post("/signup/check-invitation-code", code);
};

export const getUserDetailedInfo = (id) => {
  return API.get(`/user-info/${id}`);
};

export const getUserBasicInfo = (id) => {
  return API.get(`/user-basic-info/${id}`);
};

export const allUsers = () => {
  return API.get("/users");
};

export const getAllUsersBasicInfo = (query) => {
  const {
    limit,
    startIndex,
    search = "",
    designation = "",
    teamName = "",
    status = "",
  } = query;
  return API.get(
    `/users?designation=${designation}&teamName=${teamName}&status=${status}&limit=${limit}&startIndex=${startIndex}&search=${search}`
  );
};

export const getAllActiveEmployees = () => {
  return API.get(`/users/download/employee-list`, {
    responseType: "blob",
  });
};

export const updateUserInfo = (id, data) => {
  // console.log("update data>>>", data);
  return API.patch(`/user-info/${id}`, data);
};

export const getUser = () => {
  return API.get("/get-user");
};

export const getUsers = (data) => {
  return API.get(
    `/users?employeeType=${data.employeeType}&teamName=${data.teamName}`
  );
};

export const getUserSocialInfoByUserId = (id) => {
  return API.get(`user-info/socials/${id}`);
};

// file management
export const uploadFile = (uuid, data) => {
  // console.log("Upload data>>>>>", uuid, data);
  return API.post(`/files/fileUpload/${uuid}`, data);
};

export const editFile = (data) => {
  // console.log(data);
  return API.patch(`/files/userFile/${data.id}/update`, {
    title: data.title,
  });
}; // /userFile/:id/update

export const deleteFile = (id) => {
  // console.log("Delete data>>>>>", id);
  return API.delete(`/files/userFile/${id}`);
};

export const uploadImage = (data) => {
  return API.post("/file/image", data);
};

// for Holiday CSV Uploader
export const uploadCSV = (data) => {
  return API.patch("/events/create/bulk", data);
};
// for Holiday CSV Uploader
export const getCSVData = (startIndex) => {
  return API.get(`/events/bulk/all?startIndex=${startIndex}`);
};
// single holiday update
export const createHoliday = (data) => {
  return API.post("/events/create", data);
};
// Send Holiday Email
export const holidayEmail = (data) => {
  return API.post("/send-email/holiday", data);
};
// single holiday update
export const updateHoliday = (data) => {
  return API.patch("/events/update", data);
};
// single holiday delete
export const deleteHoliday = (id) => {
  return API.delete(`/events/delete/${id}`);
};
// Create a new event
export const createEvent = (data) => {
  return API.post("/events/session/create", data);
};
// update event
export const updateEvent = (data) => {
  return API.patch("/events/sessions/update", data);
};

// get all events
export const getAllEvents = (startIndex) => {
  return API.get(`/events/sessions/all?startIndex=${startIndex}`);
};
// delete event
export const deleteEvent = (id) => {
  return API.delete(`/events/sessions/${id}`);
};

export const changeProfilePicture = (id, data) => {
  return API.post("/user-info/profile-picture/" + id, data);
};

export const changeAboutUsImage = (id, data) => {
  return API.patch("/team-members/" + id, data);
};

export const uploadMultipleImage = (data) => {
  return API.post("/file/image-multiple", data);
};

export const getFiles = (id) => {
  // console.log(id);
  return API.get(`files/userFile/${id}`);
};
export const uerGetFiles = () => {
  return API.get(`files/userFile/`);
};

export const sendInvitation = (data) => {
  // console.log(data);
  return API.post("/send-email/invitation", data);
};

//* Admin Charts

export const getSalaryStatistics = () => {
  return API.get(`/chart/salary-stat`);
};

export const getEmployeeStatistics = () => {
  return API.get("/chart/total-employee");
};

export const getLeaveStatistics = () => {
  return API.get("/chart/leave-stat");
};

export const getLoanStatistics = () => {
  return API.get("/chart/loan-stat");
};

export const getExpenseStatistics = () => {
  return API.get("/chart/expense-stat");
};

export const getRecentLeaves = () => {
  return API.get("/chart/recent-leave");
};

export const getRecentLoanPayment = () => {
  return API.get("/chart/recent-loan");
};

export const getRecentBonusPayment = () => {
  return API.get("/chart/recent-bonus");
};

export const getBonusStatistics = () => {
  return API.get("/chart/bonus-stat");
};

export const getLifeTimeStatistics = () => {
  return API.get("/chart/lifetime-stat");
};

export const getLifeTimeExpenseStatistics = () => {
  return API.get("/chart/lifetime-expense-stat");
};

export const getEmployeeStatus = () => {
  return API.get("/chart/employee-status");
};

//* User charts

export const getUserSalaryStatistics = (uuid) => {
  return API.get(`/chart/salary-stat/${uuid}`);
};

export const getUserLeaveStatistics = (uuid) => {
  return API.get(`/chart/leave-stat/${uuid}`);
};

export const getUserLoanStatistics = (uuid) => {
  return API.get(`/chart/loan-stat/${uuid}`);
};

export const getUserRecentLoanPayment = (uuid) => {
  return API.get(`/chart/recent-loan/${uuid}`);
};

export const getUserRecentBonusPayment = (uuid) => {
  return API.get(`/chart/recent-bonus/${uuid}`);
};

export const getUserBonusStatistics = (uuid) => {
  return API.get(`/chart/bonus-stat/${uuid}`);
};

export const getUserLifeTimeStatistics = (uuid) => {
  return API.get(`/chart/lifetime-stat/${uuid}`);
};

// * Manage Salary
export const getSalary = (startIndex = 0, limit = 15, data) =>
  API.get(
    `/salary/get-salaries?designation=${data.designation}&employeeType=${data.employeeType}&search=${data.search}&teamName=${data.teamName}&startIndex=${startIndex}`
  );

export const getUserSalary = (userId) => {
  return API.get(`/salary/get-salaries/${userId}`);
};

export const getUserSalaryPayment = (userId) => {
  return API.get(`/salary/get-salaries/payment/${userId}`);
};

export const updateSalary = (userId, salaryData) => {
  return API.patch(`/salary/update-salary/${userId}`, salaryData);
};

//* Salary Pay
//* Users payment status this month
export const getUsersPayroll = ({
  designation,
  employeeType,
  search,
  selectedMonth,
  selectedYear,
  startIndex = 0,
  limit = 10,
  status = "",
  teamName = "",
}) =>
  API.get(
    `/salary/get-salaries-status/?designation=${designation}&employeeType=${employeeType}&search=${search}&selectedMonth=${selectedMonth}&selectedYear=${selectedYear}&startIndex=${startIndex}&limit=${limit}&status=${status}&teamName=${teamName}`
  );

// * Make payment
export const makePayment = (data) => API.post("/salary/pay-salary", data);

export const getUnsetTaxZoneCount = () => {
  return API.get("/salary/tax-zone/count");
};

export const getSalaryPayStatusCount = ({ month, year }) => {
  return API.get(`salary/get-status-count?month=${month}&year=${year}`);
};

export const getBulkUserSalaries = (data) => {
  return API.post(`/salary/bulk-users-salary`, data);
};

export const bulkSalaryPay = (data) => {
  return API.post(`/salary/bulk-pay-salary`, data);
};

// Salary history
export const getUserSalaryHistory = (
  userId,
  start = new Date(1970, 1, 1),
  end = new Date(2070, 1, 1)
) => API.get(`/salary/salary-history/${userId}?start=${start}&end=${end}`);

export const getUserOneSalaryHistory = (id) => {
  return API.get(`/salary/salary-history-by-id/${id}`);
};

export const getUserSalaryStatement = (userId, date) => {
  return API.get(`/salary/salary-statement/${userId}?date=${date}`);
};

// * Salary structure
export const getSalaryStructure = () => API.get(`/salary/salary-structure`);
export const createSalaryStructure = (data) =>
  API.post(`/salary/salary-structure/add`, data);

export const updateSalaryStructure = (data) =>
  API.patch(`/salary/salary-structure/${data.id}`, data);

export const deleteSalaryStructure = ({
  structure,
  newStructureId = "",
  modifiedById,
}) => {
  return API.delete(`/salary/salary-structure/${structure}`, {
    data: { newStructureId, modifiedById },
  });
};

export const searchEmployee = (query, searchBy = "email", ROLE = "manager") => {
  return API.get(
    `/search-user?query=${query}&searchBy=${searchBy}&role=${ROLE}`
  );
};

//* Manage Adjustment
export const addIncrement = (userId, data) =>
  API.patch(`/salary/adjust-salary/${userId}`, data);
export const getAdjustmentHistory = (
  startIndex = 0,
  limit = 10,
  search,
  selectedMonth,
  selectedYear
) =>
  API.get(
    `/salary/get-adjustment-history?startIndex=${startIndex}&limit=${limit}&search=${search}&selectedMonth=${selectedMonth}&selectedYear=${selectedYear}`
  );

export const getUserIncrementHistory = ({
  userId,
  start,
  end,
  startIndex = 0,
  limit = 10,
}) => {
  return API.get(
    `/salary/get-adjustment-history/${userId}?start=${start}&end=${end}&startIndex=${startIndex}&limit=${limit}`
  );
};
// Salary Sheet
export const getSalarySheet = (query) =>
  API.get(
    `/salary-sheet/?start=${query.start}&end=${query.end}&month=${query.month}&search=${query.search}`
  );
export const downloadSalarySheet = (query) =>
  API.get(
    `/salary-sheet/download/salary-sheet?start=${query.start}&end=${query.end}`,
    {
      responseType: "blob",
    }
  );
export const downloadBankSheet = (query) =>
  API.get(`/salary-sheet/download/bank-sheet`, {
    responseType: "blob",
  });

export const downloadUnpaidBonusSheet = (query) =>
  API.get(`/bonus/download-unpaid-bonus/excel`, {
    responseType: "blob",
  });

export const printSalarySheet = ({ start, end }) => {
  return API.get(`/salary-sheet/print?start=${start}&end=${end}`);
};

export const downloadUserSalarySheet = (query) =>
  API.get(
    `/salary-sheet/download?userId=${query.userID}&start=${query.start}&end=${query.end}`,
    {
      responseType: "blob",
    }
  );

export const downloadExpenseByDateSheet = ({ startDate, endDate, dateType }) =>
  API.get(
    `/expense/download-excel-date-by-date?startDate=${startDate}&endDate=${endDate}&dateType=${dateType}`,
    {
      responseType: "blob",
    }
  );
// * Extra Work

export const extraWorksbyUser = (data, startIndex, limit) => {
  // console.log(data.uuid, startIndex, limit);
  return API.get(
    `extra-work/extra-work/user/${data?.uuid}?startIndex=${startIndex}&limit=${limit}`
  );
};

export const extraWorksbyUserApproved = (data) => {
  return API.get(`extra-work/extra-work/admin/${data?.uuid}`);
};

export const createExtraWorks = (data) => {
  // console.log("data>>", data);
  return API.post(`extra-work/extra-work/create`, data);
};

// * Freelance Pay

export const getFreelanceUsers = () => {
  return API.get(`/freelance/users`);
};

export const getFreelancerById = (data) => {
  return API.get(`/freelance/users/${data?.uuid}`);
};

export const allWorksByStatus = (data) => {
  return API.get(
    `/freelance/works/users?status=${data?.status}&startIndex=${data?.startIndex}&limit=${data?.limit}`
  );
};

export const allWorksByUser = (data) => {
  return API.get(
    `/freelance/works/users/${data?.uuid}?status=${data?.status}&paymentTo=${data?.paymentToId}`
  );
};

export const getFreelanceWorks = (
  startIndex = 0,
  limit = 10,
  search,
  statusType
) => {
  return API.get(
    `/freelance/works?startIndex=${startIndex}&limit=${limit}&search=${search}&status=${statusType}`
  );
};
export const getOwnFreelanceWorks = (data) => {
  return API.get(`/freelance/users/${data?.uuid}/works`);
};

export const postFreelancePay = (data) => {
  return API.post(`/freelance/works/users/${data?.uuid}/payment`, data);
};

export const postReimburseFreelancePay = (data) => {
  return API.post(`/freelance/works/${data?.id}/reimburse`, data);
};

export const createFreelanceWork = (data) => {
  return API.post(`/freelance/works/create`, data);
};

export const patchFreelanceRate = (data) => {
  return API.patch(`/freelance/users/${data?.id}/manage-rate`, data);
};

// * Loan Management

export const getAllLoanData = ({
  startIndex = 0,
  limit = 10,
  search,
  teamName,
  installmentStatus,
  selectedDate = "",
}) => {
  return API.get(
    `/loan?startIndex=${startIndex}&limit=${limit}&search=${search}&teamName=${teamName}&installmentStatus=${installmentStatus}&selectedDate=${selectedDate}`
  );
};

export const getLoanDataByUserId = ({
  uuid,
  selectedDate = "",
  status = "",
}) => {
  return API.get(`/loan/${uuid}?date=${selectedDate}&status=${status}`);
};

export const getLoanDataByLoanId = (id) => {
  return API.get(`/loan/loan-info/${id}`);
};

export const getLoanDataByStatus = (status) => {
  return API.get(`/loan-filter/${status}`);
};

export const getLoanCountsByStatus = () => {
  return API.get("/loan-filter/loan-counts");
};

export const getSkipRequestsCount = () => {
  return API.get("/loan-filter/loan-counts/skip-requests");
};

export const getSkipRequests = () => {
  return API.get("/loan-filter/skip-requests");
};

export const applyForLoan = (data) => {
  return API.post("/loan", data);
};

export const updateLoanDataByLoanId = (loanId, data) => {
  return API.patch(`/loan/update/${loanId}`, data);
};

export const getLoanHistoryByUserId = (uuid) => {
  return API.get(`/loan-history/${uuid}`);
};

export const getLoanHistoryByLoanId = (loanId) => {
  return API.get(`/loan-history/loan-id/${loanId}`);
};

export const sendSalaryInvoice = (id) => {
  return API.post(`/send-email/payment-invoice/${id}`);
};

export const downloadIncrementHistory = (id) => {
  return API.get("/pdf/download/adjustment-history", { responseType: "blob" });
};

export const sendIncrementHistory = (id) => {
  return API.post(`/send-email/adjustment-history/${id}`);
};
export const addLoanHistory = (data) => {
  return API.post("/loan-history", data);
};

export const requestSkipInstallment = (data) => {
  return API.post("/skip-installment", data);
};

export const getLatestSkippedInstallment = (loanId) => {
  return API.get(`/skip-installment/latest/loan/${loanId}`);
};

export const updateSkippedInstallmentById = (skipId, data) => {
  return API.patch(`/skip-installment/update/${skipId}`, data);
};

export const getLoanStructure = (id) => {
  return API.get(`/loan-structure/${id}`);
};

export const getLoanStructures = () => {
  return API.get(`/loan-structure`);
};

export const getLoanStructureWithSalary = (userId) => {
  return API.get(`/loan-structure/active/${userId}`);
};

export const addLoanStructure = (data) => {
  return API.post("/loan-structure", data);
};

export const getLoanDetailedHistory = (id) => {
  return API.get(`/loan-history/details/${id}`);
};

export const updateLoanStructureById = (id, data) => {
  return API.patch(`/loan-structure/update/${id}`, data);
};

export const deleteLoanStructure = (id) => {
  return API.delete(`/loan-structure/delete/${id}`);
};

// export const updateLoanStructure = (data) => {
//   return API.patch(`/loan-structure`, data);
// };

//-------get excel data fo advance salary-------//
export const getLoanExcelData = ({ selectedMonth, selectedYear }) => {
  return API.get(
    `/loan/sheet/download?selectedMonth=${selectedMonth}&selectedYear=${selectedYear}&force=true`,
    {
      responseType: "blob",
    }
  );
};
//-------get excel data fo advance salary-------//

//-------get excel data for Expense-------//
export const getExpenseExcelData = ({ selectedMonth, selectedYear }) => {
  return API.get(
    `/expense/sheet/download/excel?selectedMonth=${selectedMonth}&selectedYear=${selectedYear}&force=true`,
    {
      responseType: "blob",
    }
  );
};
//-------get excel data for Expense-------//

//-------get excel data to bank for Expense-------//
export const getExpenseExcelDataToBank = ({ selectedMonth, selectedYear }) => {
  return API.get(
    `/expense/sheet/download/toBank?selectedMonth=${selectedMonth}&selectedYear=${selectedYear}&force=true`,
    {
      responseType: "blob",
    }
  );
};
//-------get excel data for Expense-------//

//* Salary

export const downloadSalaryInvoice = (id) => {
  return API.get("/pdf/download/payment-invoice", { responseType: "blob" });
};

//* Bonus
export const addBonus = (bonusData) => {
  return API.post(`/bonus/add-bonus`, bonusData);
};

export const addBulkBonus = (bonusData) => {
  return API.post(`/bonus/add-bulk-bonus`, bonusData);
};

export const getBonusHistoryById = (id) => {
  return API.get(`/bonus/history/${id}`);
};

export const getBonuses = () => {
  return API.get(`/bonus`);
};

export const getAllBonusHistory = ({ search = "", startIndex }) => {
  return API.get(`/bonus/get-all?search=${search}&startIndex=${startIndex}`);
};

export const payBonus = (data) => {
  return API.post(`/bonus/pay-bonus`, data);
};

export const rejectBonus = (data) => {
  return API.post(`/bonus/reject-bonus`, data);
};

export const getTeamMembers = (teams) => {
  return API.post(`/bonus/get-employees`, teams);
};

export const getExtraWork = (
  startIndex = 0,
  limit = 15,
  search,
  teamName,
  month,
  year,
  currentMonth,
  currentYear,
  startRangeDay,
  startRangeMonth,
  startRangeYear,
  endRangeDay,
  endRangeMonth,
  endRangeYear
) => {
  return API.get(
    `/extra-work/extra-work?startIndex=${startIndex}&limit=${limit}&search=${search}&teamName=${teamName}&month=${month}&year=${year}&currentMonth=${currentMonth}&currentYear=${currentYear}&startRangeDay=${startRangeDay}&startRangeMonth=${startRangeMonth}&startRangeYear=${startRangeYear}&endRangeDay=${endRangeDay}&endRangeMonth=${endRangeMonth}&endRangeYear=${endRangeYear}`
  );
};

export const getExtraWorkStatusCount = ({
  currentMonth,
  currentYear,
  startRangeDay,
  startRangeMonth,
  startRangeYear,
  endRangeDay,
  endRangeMonth,
  endRangeYear,
}) => {
  return API.get(
    `/extra-work/extra-work-status-count?currentMonth=${currentMonth}&currentYear=${currentYear}&startRangeDay=${startRangeDay}&startRangeMonth=${startRangeMonth}&startRangeYear=${startRangeYear}&endRangeDay=${endRangeDay}&endRangeMonth=${endRangeMonth}&endRangeYear=${endRangeYear}`
  );
};

export const getUpdateExtraWork = (data) => {
  return API.patch(`/extra-work/extra-work/${data?.id}/update`, data);
};

export const getAllAdvanceSalary = (
  startIndex,
  limit,
  debouncedSearch,
  teamId
) => {
  return API.get(
    `/salary/advance-salary?startIndex=${startIndex}&limit=${limit}&search=${debouncedSearch}&teamId=${teamId}`
  );
};

export const getUserAdvanceSalary = (data, startIndex, limit) => {
  // console.log("check**", data.uuid, startIndex, limit);
  return API.get(
    `/salary/advance-salary/user/${data?.uuid}?startIndex=${startIndex}&limit=${limit}&status=${data?.status}`
  );
};

export const advanceSalary = (data) => {
  return API.post(`/salary/advance-salary/create`, data);
};

export const editAdvancePay = (data) => {
  return API.patch(`/salary/advance-salary/${data?.id}/edit`, data);
};

//* Expense

export const submitExpense = (data) => {
  return API.post("/expense/add", data);
};
export const submitAdvancedBudget = (data) => {
  return API.post("/budgets/create", data);
};

export const submitBenificiaryAccount = (data) => {
  return API.post("/benficiary-accounts/create", data);
};

export const deleteBenificiaryAccount = (id) => {
  return API.delete(`/benficiary-accounts/${id}`);
};

export const updateBenificiaryAccount = (id, data) => {
  return API.patch(`/benficiary-accounts/${id}`, data);
};

export const submitFinalEexpenseOfBudget = (data) => {
  return API.post("/budgets/create/final-expense", data);
};
export const submitDraftHisotryOfSubmission = (data) => {
  return API.post("/budgets/create-draft", data);
};

export const updateAdvancedBudget = (budgetId, data) => {
  console.log(data, "data");
  return API.patch(`/budgets/${budgetId}`, data);
};

export const deleteImageAdvancedBudget = (imageId) => {
  return API.delete(`/budgets/delete-image/${imageId}`);
};

export const submitPartialSubmit = (data) => {
  return API.post("/budgets/create/partial-submit", data);
};

export const submitExtraBudget = (data) => {
  return API.post("/budgets/create-extra-budget", data);
};
export const updateExtraBudget = ({ data, id }) => {
  return API.patch(`/budgets/extra-budget/${id}`, data);
};

export const updatePartialSubmit = ({ data, id }) => {
  return API.patch(`/budgets/partial-submit/${id}`, data);
};
export const currentUserExpenses = (
  id,
  search = "",
  startIndex = 0,
  limit = 15,
  status
) => {
  return API.get(
    `/expense/${id}?search=${search}&startIndex=${startIndex}&limit=${limit}&status=${status}`
  );
};
export const allExpenses = (queryParam) => {
  const { teamId, limit, startIndex, search, status } = queryParam;
  return API.get(
    `/expense?teamId=${teamId}&limit=${limit}&startIndex=${startIndex}&search=${search}&status=${status}`
  );
};
export const allUserExpenses = (queryParam) => {
  const { teamName, limit, startIndex, search, status } = queryParam;

  return API.get(
    `/expense/users?teamName=${teamName}&limit=${limit}&startIndex=${startIndex}&search=${search}&status=${status}`
  );
};
export const allUserExpensesAsManager = (queryParam) => {
  const { teamId, limit, startIndex, search, status } = queryParam;
  return API.get(
    `/expense/manager?teamId=${teamId}&limit=${limit}&startIndex=${startIndex}&search=${search}&status=${status}`
  );
};
export const expenseByUserId = (queryParam) => {
  return API.get(
    `/expense/users/${queryParam.userId}?status=${queryParam.status}&paymentTo=${queryParam.paymentTo}&month=${queryParam.month}&year=${queryParam.year}`
  );
};
export const expenseByGroupId = (id) => {
  return API.get(`/expense/detail/group/${id}`);
};
export const expenseDetails = (id) => {
  return API.get("/expense/detail/" + id);
};

/**
 * Expense banks
 */
export const getExpenseBanks = () => {
  return API.get("/expense/bank-account");
};

export const addExpenseBank = (data) => {
  return API.post("/expense/bank-account", data);
};

export const getExpenseBanksById = (id) => {
  return API.get("/expense/bank-account/" + id);
};
// End of expense banks

export const updateExpenseReimbursemnet = (id, data) => {
  return API.patch("/expense/detail/" + id, data);
};
export const expensePay = (userId, data) => {
  return API.post(`/expense/users/${userId}`, data);
};

export const getExpenseStatusCount = (month, year) => {
  return API.get(`/expense/status-count?month=${month}&year=${year}`);
};

export const getAllExpenseApprover = () => {
  return API.get(`/expense/approver`);
};

//* Leave Structure

export const getAllLeaveStructures = () => {
  return API.get(`/leave-structure/get-all`);
};

export const getAllLeaveCategory = (startIndex = 0, limit = 15, search) => {
  return API.get(
    `/leave-structure/leave-category?startIndex=${startIndex}&limit=${limit}&search=${search}`
  );
};

export const addLeaveStructure = (data) => {
  return API.post(`/leave-structure/add`, data);
};

export const updateLeaveStructure = (data, id) => {
  return API.patch(`/leave-structure/update/${id}`, data);
};

export const deleteLeaveStructure = (id) => {
  return API.delete(`/leave-structure/delete/${id}`);
};

//* Leave

export const getAllLeaves = ({ status, search, teamName, startIndex }) => {
  return API.get(
    `/leave?status=${status}&search=${search}&teamName=${teamName}&startIndex=${startIndex}`
  );
};

export const getLeaveBoard = ({ startIndex, limit = 15, search, teamName }) => {
  // console.log("view: ", startIndex, limit, search);
  return API.get(
    `/leave/users?startIndex=${startIndex}&limit=${limit}&search=${search}&teamName=${teamName}`
  );
};

export const getLeaveStatusCount = () => {
  return API.get(`/leave/status-count`);
};

export const applyForLeave = (uuid, data) => {
  return API.post(`/leave/${uuid}`, data);
};

export const getUserLeaveStatusCount = () => {
  return API.get(`/leave/user-status-count`);
};

export const getUserLeaveCount = (uuid, categoryId) => {
  return API.get(
    `/leave/user-leave-count?uuid=${uuid}&categoryId=${categoryId}`
  );
};

export const getLeaveDetailsById = (id) => {
  return API.get(`/leave/detail/${id}`);
};

export const getUserLeaves = ({ status, startIndex, limit, uuid }) => {
  return API.get(
    `/leave/user/${uuid}?status=${status}&startIndex=${startIndex}&limit=${limit}`
  );
};
export const getUserLeavesByCategory = ({ status, startIndex, uuid }) => {
  return API.get(
    `/leave/user/${uuid}/leaves?status=${status}&startIndex=${startIndex}`
  );
};

// export const getUserLeavesByCategoryYearly = ({ status, startIndex, uuid }) => {
//   return API.get(
//     `/leave/yearly/user/${uuid}/leaves?status=${status}&startIndex=${startIndex}`
//   );
// };

export const updateLeave = (id, data) => {
  return API.patch(`/leave/${id}`, data);
};
export const changePassword = (data) => API.patch("/change-password", data);

export const swtichRole = (data) => API.patch("/switch-role", data);

export const createDesignation = (data) =>
  API.post("/team-management/create-designation", data);
export const updateDesignation = (data) =>
  API.patch("/team-management/update-designation", data);

export const allDesignations = () => API.get("/team-management/designations");

export const deleteDesignation = (id) =>
  API.post(`/team-management/delete-designation/${id}`);

export const forgotPass = (info) => API.post("/forgot-password", info);

export const checkForgotPasswordCode = (code) => {
  return API.post("/forgot-password/check-code", code);
};

export const resetPassword = (info) => {
  return API.patch("/forgot-password/reset", info);
};

// Team management
export const createTeam = (teamInfo) => {
  return API.post("/team", teamInfo);
};

export const getTeams = () => {
  return API.get("/team");
};

export const getTeamMemberCount = (id) => {
  return API.get(`/team/member-count/${id}`);
};

export const updateTeam = (id, teamInfo) => {
  return API.patch(`/team/${id}`, teamInfo);
};

export const deleteTeam = (id) => {
  return API.post(`/team/${id}`);
};

export const getMyAvailability = (data) => {
  return API.get(`/team-availability/user/${data?.uuid}`);
};

export const getTeamAvailability = (data) => {
  return API.get(`/team-availability/team/${data?.uuid}`);
};

export const addAvailability = (data) => {
  return API.patch(`/team-availability/create`, data);
};

// Scrum Update
export const postUpdate = (data) => {
  return API.patch("/update/create", data);
};

export const getTimeHistory = (data) => {
  return API.get(`/update/user/history/${data?.uuid}`);
};

export const getUserRangeUpdates = (data) => {
  return API.get(
    `/update/user/range/?uuid=${data?.uuid}&start=${data?.start}&end=${data?.end}&startIndex=${data?.startIndex}&limit=${data?.limit}`
  );
};

export const getUserScrumUpdates = (data) => {
  return API.get(
    `/update/user/all/?uuid=${data?.uuid}&startIndex=${data?.startIndex}&limit=${data?.limit}`
  );
};

export const getTeamUpdate = (uuid, isExcluded = false, isPrev = false) => {
  return API.get(
    `/update/team/${uuid}/?isExcluded=${isExcluded}&isPrev=${isPrev}`
  );
};

// export const getTeamUpdate = (data) => {
//   return API.get(`update/team/${data?.uuid}?startIndex=${data?.startIndex}&limit=${data?.limit}`);
// }

export const checkIfUpdatedLately = (data) => {
  return API.post(`/update/check`, data);
};

export const checkIfAtttended = (data) => {
  return API.post(`/update/check/attendance`, data);
};

export const saveAttendance = (data) => {
  return API.patch(`/update/attendance`, data);
};

export const getUserUpdateByDate = (data) => {
  return API.post("/update/date", data);
};
export const getUserDraftByDate = (data) => {
  return API.post("/update/draft/date", data);
};

export const patchJobDetails = (data) => {
  return API.patch("/update/job-details", data);
};

export const patchUserUpdateToday = (data) => {
  return API.patch("/update/create/today", data);
};
export const patchUserUpdateNext = (data) => {
  return API.patch("/update/create/next", data);
};
export const patchUserUpdateDependencies = (data) => {
  return API.patch("/update/create/dependencies", data);
};
export const patchUserUpdateDraft = (data) => {
  return API.patch("/update/create/draft", data);
};

export const getPastTeamJobDetails = (data) => {
  return API.get(
    `/update/job-details/team/past/${data?.teamId}/?startIndex=${data?.startIndex}&limit=${data?.limit}&search=${data?.search}`
  );
};

export const getTeamJobDetails = (teamId) => {
  return API.get(`/update/job-details/team/${teamId}`);
};

export const patchJobBulkDetails = (data) => {
  return API.patch("/update/job-details/bulk", data);
};

export const getManagerTeams = (uuid) => {
  return API.get(`/update/manager/${uuid}/teams`);
};

export const getOffDays = (uuid) => {
  return API.get(`/update/off-days/${uuid}/get`);
};

export const getTeamOffDays = (uuid) => {
  return API.get(`/update/off-days/${uuid}/team`);
};

export const getOffDaysHistories = (uuid) => {
  return API.get(`/update/off-days/${uuid}/history`);
};

export const checkHistoryStatus = () => {
  return API.patch(`/update/check/status`);
};
export const checkUserOffDays = (data) => {
  return API.patch(`/update/off-days/user/default`, data);
};
export const checkOffDays = () => {
  return API.patch(`/update/off-days/default`);
};
export const updateOffDays = (data) => {
  return API.patch(`/update/off-days/update`, data);
};

// Evaluation
export const createEvaluationForm = (data) => {
  return API.post(`/evaluation/form`, data);
};

export const getEvaluationForms = () => {
  return API.get("/evaluation/form");
};

export const getFormDetails = (id) => {
  return API.get(`/evaluation/form/${id}`);
};

export const getFormDetailsForEdit = (id) => {
  return API.get(`/evaluation/form/edit/${id}`);
};

export const editEvaluationForm = (id, data) => {
  return API.patch(`/evaluation/form/edit/${id}`, data);
};

export const deleteEvaluationForm = (id) => {
  return API.delete(`/evaluation/form/${id}`);
};

export const sendEvaluationRequest = (info) => {
  return API.post(`/evaluation/request`, info);
};

export const getEvaluationRequests = (status) => {
  return API.get(`/evaluation/request?status=${status}`);
};

export const getEvaluationDetails = (id) => {
  return API.get(`/evaluation/request/${id}`);
};

export const submitEvaluationReport = (data) => {
  return API.post(`/evaluation/report`, data);
};

export const getEvaluationReport = (id) => {
  return API.get(`/evaluation/report/${id}`);
};

export const getEvaluationReportDetails = (id) => {
  return API.get(`/evaluation/report-details/${id}`);
};

export const getUserEvaluations = () => {
  return API.get(`/evaluation/report`); // for user's own view
};

export const getUserEvaluationHistory = (id) => {
  return API.get(`/evaluation/report/user/${id}`); // for admin view
};

export const getEvaluationUsers = ({
  status = "",
  startIndex = 0,
  search = "",
  team = "",
  employeeType = "",
}) => {
  return API.get(
    `/evaluation/get-users?status=${status}&startIndex=${startIndex}&search=${search}&team=${team}&employeeType=${employeeType}`
  );
};
export const initializeEvaluation = (data) => {
  return API.post(`/evaluation/set-users`, data);
};
export const getEvaluationCount = () => {
  return API.get(`/evaluation/get-count`);
};
export const getEvaluationsByType = ({
  type,
  status = "Pending",
  startIndex = 0,
  search = "",
  team = "",
}) => {
  return API.get(
    `/evaluation/get-evaluation?evaluationType=${type}&status=${status}&startIndex=${startIndex}&search=${search}&team=${team}`
  );
};

export const getEvaluationStatusCount = (type) => {
  return API.get(`/evaluation/get-status-count?type=${type}`);
};

export const getManagersEvaluationStatusCount = (managerId) => {
  return API.get(`/evaluation/get-status-count/${managerId}`);
};

export const sendEvaluationMail = (id) => {
  return API.post(`/send-email/evaluation/${id}`);
};

//* Settings

export const getAdjustmentPurpose = () => {
  return API.get(`/settings/adjustment-purpose`);
};

export const createAdjustmentPurpose = (data) => {
  return API.post(`/settings/adjustment-purpose/create`, data);
};
export const deleteAdjustmentPurpose = (id) => {
  return API.delete(`/settings/adjustment-purpose/${id}`);
};

export const getAllBonuses = () => {
  return API.get("/settings/bonuses");
};
export const createBonus = (data) => {
  return API.post("/settings/bonus/create", data);
};
export const deleteBonus = (id) => {
  return API.delete("/settings/bonus/" + id);
};

export const createBank = (data) => {
  return API.post("/bank/create", data);
};

export const createBankBulk = (data) => {
  return API.post("/bank/bulk-create", data);
};

export const searchBank = (query = "") => {
  return API.get(`/bank/search?query=${query}`);
};

export const getBanks = () => {
  return API.get(`/bank`);
};

export const updateBank = (id, data) => {
  return API.patch(`/bank/${id}`, data);
};

export const deleteBank = (id) => {
  return API.delete(`/bank/${id}`);
};

export const allBanksDetails = ({
  search = "",
  startIndex = 0,
  status = "",
}) => {
  return API.get(
    `/bank/details?search=${search}&startIndex=${startIndex}&status=${status}`
  );
};

export const getBankStatusCount = () => {
  return API.get(`/bank/status`);
};

//--------Bank Change Info Action Log routes-------//

// get route  //
export const bankChangeInfoOfUser = (
  data,
  startIndex = 0,
  limit = 15,
  search
) => {
  // console.log(data.uuid, startIndex, limit);
  return API.get(
    `change-info-action/bank-change?startIndex=${startIndex}&limit=${limit}&search=${search}`
  );
};

export const bankChangeInfoForUser = (
  data,
  startIndex = 0,
  limit = 15,
  search
) => {
  // console.log(data.uuid, startIndex, limit);
  return API.get(
    `change-info-action/bank-change/user?startIndex=${startIndex}&limit=${limit}&search=${search}&userID=${data.uuid}`
  );
};

// Bank info action log route for reject //
export const bankChangeInfoLogUpdate = (data) => {
  // console.log("bankChangeInfoUpdate>>>", data);
  return API.patch(`change-info-action/bank-change/${data?.id}`, data);
};

// Bank info change route for Accept //
export const updateBankInfo = (data) => {
  // console.log("bankChangeInfoUpdate>>>", data);
  return API.patch(`change-info-action/bank-change/${data?.id}/update`, data);
};

//--------Bank Change Info Action Log routes-------//

export const getEmployeeStatusCount = () => {
  return API.get(`/users/status-count`);
};
export const getSCBtoSCB = ({
  selectedMonth,
  selectedYear,
  force,
  teamName = "",
}) => {
  if (force) {
    return API.get(
      `/salary-sheet/download/scb-to-scb?selectedMonth=${selectedMonth}&selectedYear=${selectedYear}&force=true&teamName=${teamName}`,
      {
        responseType: "blob",
      }
    );
  } else {
    return API.get(
      `/salary-sheet/download/scb-to-scb?selectedMonth=${selectedMonth}&selectedYear=${selectedYear}&teamName=${teamName}`
    );
  }
};
export const getSCBtoOthers = ({
  selectedMonth,
  selectedYear,
  force,
  teamName = "",
}) => {
  if (force) {
    return API.get(
      `/salary-sheet/download/scb-to-others?selectedMonth=${selectedMonth}&selectedYear=${selectedYear}&force=true&teamName=${teamName}`,
      {
        responseType: "blob",
      }
    );
  } else {
    return API.get(
      `/salary-sheet/download/scb-to-others?selectedMonth=${selectedMonth}&selectedYear=${selectedYear}&teamName=${teamName}`
    );
  }
};

//-------get excel data fo advance salary-------//
export const getAdvanceSalaryExcelData = ({ selectedMonth, selectedYear }) => {
  return API.get(
    `/advance-salary-sheet/sheet/download?selectedMonth=${selectedMonth}&selectedYear=${selectedYear}&force=true`,
    {
      responseType: "blob",
    }
  );
};
//-------get excel data fo advance salary-------//

// ---------- activity log get API --------- //

export const getActivityLogs = (startIndex = 0, limit = 15, search, role) => {
  // console.log(data.uuid, startIndex, limit);
  return API.get(
    `activity-logs?startIndex=${startIndex}&limit=${limit}&search=${search}&roleName=${role}`
  );
};

export const getNotificationLogs = (
  startIndex = 0,
  limit = 15,
  role,
  userName
) => {
  // console.log("startIndex>>>", startIndex);
  // console.log("limit>>>", limit);
  return API.get(
    `notification-logs?startIndex=${startIndex}&limit=${limit}&roleName=${role}&userName=${userName}`
  );
};

// ---------- activity log get API --------- //

export const getNavStat = () => {
  return API.get(`/nav-stat`);
};

// ---------- Subscription API's --------- //

// Add Subscription
export const addSubscription = (subscriptionInfo) => {
  return API.post("/manage-service/subscription", subscriptionInfo);
};

export const addRenewSubscription = (renewInfo, id) => {
  // console.log("renewInfo>>", renewInfo);
  // console.log("id>>", id);
  return API.post(`/manage-service/subscription/renew/${id}`, renewInfo);
};

export const updateSubscription = (updateSubscriptionInfo, id) => {
  return API.patch(
    `/manage-service/subscription/${id}`,
    updateSubscriptionInfo
  );
};

export const deleteSubscription = (id) => {
  return API.delete(`/manage-service/subscription/${id}`);
};

// Add Digital Access
export const addDigitalAccess = (digitalAccessData) => {
  return API.post("/manage-service/digital-access", digitalAccessData);
};

export const deleteDigitalAccess = (id) => {
  return API.delete(`/manage-service/digital-access/${id}`);
};

export const updateDigitalAccess = (id, updateDigitalAccess) => {
  return API.patch(`/manage-service/digital-access/${id}`, updateDigitalAccess);
};

export const getManageService = (
  activeTab,
  startIndex,
  limit,
  selectedData = ""
) => {
  return API.get(
    `/manage-service/all-${activeTab}?startIndex=${startIndex}&limit=${limit}&selectedData=${selectedData}`
  );
};

// Get Subscription
export const getSubscriptionHistory = (
  id,
  serviceName,
  startIndex,
  limit
  // debouncedSearch,
  // team,
  // role
) => {
  return API.get(
    `/manage-service/subscription/history/${id}?serviceName=${serviceName}&startIndex=${startIndex}&limit=${limit}`
  );
};

export const getAllSubscription = (startIndex, limit, debouncedSearch = "") => {
  return API.get(
    `/manage-service/all-subscription?startIndex=${startIndex}&limit=${limit}&search=${debouncedSearch}`
  );
};
export const getSubscription = (
  startIndex,
  limit,
  debouncedSearch = "",
  role,
  team
) => {
  return API.get(
    `/manage-service/subscription?startIndex=${startIndex}&limit=${limit}&search=${debouncedSearch}&role=${role}&team=${team}`
  );
};

// Get Subscription user
export const getSubscriptionUser = (
  id,
  startIndex,
  limit,
  debouncedSearch,
  team,
  role
) => {
  return API.get(
    `/manage-service/subscription/users/${id}?startIndex=${startIndex}&limit=${limit}&search=${debouncedSearch}&role=${role}&team=${team}`
  );
};
// Add Subscription user
export const addSubscriptionUser = (userInfo) => {
  return API.post("/manage-service/subscription-user", userInfo);
};

export const updateSubscriptionUser = (id, updateUserInfo) => {
  return API.patch(
    `/manage-service/subscription/user/update/${id}`,
    updateUserInfo
  );
};
export const deleteSubscriptionUser = (id) => {
  return API.delete(`/manage-service/subscription/user/delete/${id}`);
};

// Get Digital Access

export const getAllDigitalAccess = (
  startIndex,
  limit,
  debouncedSearch = ""
) => {
  return API.get(
    `/manage-service/all-digital-access?startIndex=${startIndex}&limit=${limit}&search=${debouncedSearch}`
  );
};

export const getDigitalAccess = (
  startIndex,
  limit,
  debouncedSearch = "",
  team,
  role,
  activeTab
) => {
  return API.get(
    `/manage-service/digital-access/${activeTab}?startIndex=${startIndex}&limit=${limit}&search=${debouncedSearch}&team=${team}&role=${role}`
  );
};

// Get Digital Access user
export const getDigitalAccessUser = (
  id,
  startIndex,
  limit,
  debouncedSearch,
  team,
  role,
  activeTab
) => {
  return API.get(
    `/manage-service/digital-access/${activeTab}/${id}?startIndex=${startIndex}&limit=${limit}&search=${debouncedSearch}&team=${team}&role=${role}`
  );
};
// add Digital Access user
export const addDigitalAccessUser = (data) => {
  return API.post(`/manage-service/digital-access/user`, data);
};

export const updateDigitalAccessUser = (id, data) => {
  return API.patch(`/manage-service/digital-access/user/update/${id}`, data);
};

export const deleteDigitalAccessUser = (id) => {
  return API.delete(`/manage-service/digital-access/user/delete/${id}`);
};

export const getManageServiceStatusCount = (service) => {
  // console.log("service>>>", service);
  return API.get(`/manage-service/status-count/all-${service}`);
};

export const getSubscriptionStatusCount = (team, role) => {
  // console.log("service>>>", service);
  return API.get(
    `/manage-service/status-count/subscription?team=${team}&role=${role}`
  );
};

export const getDeposits = (limit = 10, startIndex = 0, monthYear) => {
  return API.get(
    insertQuery(`/debit-credit/deposits`, {
      limit,
      startIndex,
      month: monthYear?.month,
      year: monthYear?.year,
    })
  );
};

export const createDeposit = (data) => {
  return API.post(`/debit-credit/deposits/create`, data);
};

export const getCurrentBalance = () => {
  return API.get(`/debit-credit/current-balance`);
};

export const getSpendingHistory = (limit, startIndex, monthYear) => {
  return API.get(
    insertQuery(`/debit-credit/debits`, {
      limit,
      startIndex,
      month: monthYear?.month,
      year: monthYear?.year,
    })
  );
};

export const createSpending = (data) => {
  return API.post(`/debit-credit/debits/create`, data);
};

export const getSpendableExpenses = (year, month) => {
  return API.get(
    `/debit-credit/debitable-expenses?year=${year}&month=${month}`
  );
};
